$primary: #7f48e6;

$body-bg: rgb(233, 232, 232);

$card-height: 90%;

.jumbotron {
    background-color: #ffffff !important;
}

.navbar-brand {
    margin-left:0.7em;
}

.navbar-nav {
    margin-right:0.7em;
}

.navbar {
    margin-bottom:1em;
}

.education-img {
    width: 5em;
    height: 5em;
}

.navbarImages {
    width:1.5em;
    height:1.5em;
}

.card-link {
    color: $primary;
}

@import "~bootstrap/scss/bootstrap";